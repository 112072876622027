import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DecimalToTimePipe } from '@app/pipes/decimal-to-time.pipe';
import { AbsenceExpectedResponse } from '@app/reducers/orm/absence/absence.model';
import { SbFormFieldComponent } from '@app/shared/sb-lib/forms/sb-form-field.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getDayNames } from '@shiftbase-com/utilities';
import { clsx } from 'clsx';

import { TranslationParamsPipe } from '../../../../pipes/translation-params.pipe';
import { AbsenceSidebarCardComponent } from './absence-sidebar-card.component';

@Component({
  selector: 'absence-expected-hours',
  templateUrl: './absence-expected-hours.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SbFormFieldComponent,
    TranslateModule,
    DecimalToTimePipe,
    TranslationParamsPipe,
    AbsenceSidebarCardComponent,
  ],
})
export class AbsenceExpectedHoursComponent implements OnInit {
  // TODO temporary input, can be removed once we have reintroduced the sidebar in absence edit
  @Input() public asCard = false;

  @HostBinding('class')
  public get class() {
    return clsx(['flex flex-col', this.asCard ? 'gap-4' : 'gap-6']);
  }

  @Input() public expectedHours: AbsenceExpectedResponse;
  @Input() public canApprove: boolean;

  public weekDays: string[] = [];

  public constructor(private readonly translateService: TranslateService) {}

  public ngOnInit(): void {
    this.weekDays = getDayNames(this.translateService.currentLang);
  }
}
