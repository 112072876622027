<sb-form-field
  [control]="periodControl"
  [fullWidth]="true"
  [noPadding]="true"
  [label]="openEnded ? 'Absence period start date' : ('Absence period' | translate)"
  [overrideError]="hasAbsenceRestrictionConflict"
  [overrideErrorMessage]="'No absence may be requested during the selected period' | translate"
>
  @if (openEnded) {
    <date-input [formControl]="periodControl" [appendTo]="'body'" [fullWidth]="true" />
  } @else {
    <date-range-picker [formControl]="periodControl" [appendTo]="'body'" [styleClass]="'w-full'" />
  }
</sb-form-field>
