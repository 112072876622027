@if (asCard) {
  <absence-sidebar-card [title]="'Time off balances' | translate">
    <div class="grid grid-cols-3 gap-1">
      <span class="font-semibold">{{ 'Balance' | translate }}</span>
      <span class="font-semibold">{{
        absenceEndDate | localeDate: { year: 'numeric', month: 'short', day: 'numeric' }
      }}</span>
      <span class="font-semibold">{{
        balanceEndDate | localeDate: { year: 'numeric', month: 'short', day: 'numeric' }
      }}</span>
      @for (balance of balanceStats; track balance.id) {
        <span>{{ balance.name }}</span>
        <span>
          {{ balance.periodEnd | decimalToDurationFormat: balance.unit : { dayRoundingRule: 'halfDays' } }}
        </span>
        <span>
          {{ balance.yearEnd | decimalToDurationFormat: balance.unit : { dayRoundingRule: 'halfDays' } }}
        </span>
      }
    </div>
  </absence-sidebar-card>
} @else {
  <!-- TODO not really a form field, but I need consistently styled labels -->
  <sb-form-field [fullWidth]="true" [noPadding]="true" [label]="'Time off balances' | translate">
    <p class="flex items-center gap-1 text-sm font-semibold text-grey-500">
      {{
        'Expected until: [balanceEndDate]'
          | translate
          | translationParams
            : { balanceEndDate: balanceEndDate | localeDate: { year: 'numeric', month: 'long', day: 'numeric' } }
      }}
      @if (endContractDate) {
        <sb-icon
          [name]="'question-circle'"
          [sbTooltip]="
            'Contract ends on [endContractDate]'
              | translate
              | translationParams
                : { endContractDate: endContractDate | localeDate: { year: 'numeric', month: 'long', day: 'numeric' } }
          "
          [sbTooltipPlacement]="'top'"
          [sbTooltipMaxWidth]="'18rem'"
        ></sb-icon>
      }
    </p>
    <div class="mt-1 grid grid-cols-2 gap-1">
      @for (balance of balanceStats; track balance.id) {
        <span>{{ balance.name }}</span>
        <span class="text-right">
          {{ balance.yearEnd | decimalToDurationFormat: balance.unit : { dayRoundingRule: 'halfDays' } }}
        </span>
      }
    </div>
  </sb-form-field>
}
