import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'absence-sidebar-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './absence-sidebar-card.component.html',
})
export class AbsenceSidebarCardComponent {
  @Input()
  public title: string | SafeHtml;
}
