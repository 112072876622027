import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateInputComponent } from '@app/shared/sb-lib/calendar/date-input/date-input.component';
import { DateRangePickerComponent } from '@app/shared/sb-lib/calendar/date-range/date-range-picker.component';
import { SbFormFieldComponent } from '@app/shared/sb-lib/forms/sb-form-field.component';
import { TranslateModule } from '@ngx-translate/core';

import { AbsencePeriodValue } from '../absence-request.models';

@Component({
  selector: 'absence-request-period-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SbFormFieldComponent,
    DateInputComponent,
    DateRangePickerComponent,
  ],
  templateUrl: './absence-request-period-field.component.html',
})
export class AbsenceRequestPeriodFieldComponent {
  @Input()
  public openEnded: boolean;
  @Input()
  public periodControl: FormControl<AbsencePeriodValue>;
  @Input()
  public hasAbsenceRestrictionConflict: boolean;
}
