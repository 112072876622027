@if (expectedHours && expectedHours.week_contract.contract_hours !== '-') {
  @if (asCard) {
    <absence-sidebar-card [title]="'Period hours' | translate">
      <ng-container *ngTemplateOutlet="periodHoursContent" />
    </absence-sidebar-card>
    <absence-sidebar-card
      [title]="('Contract hours' | translate) + ' ' + (expectedHours.week_contract.contract_hours | decimalToTime)"
    >
      <ng-container *ngTemplateOutlet="contractHoursContent" />
    </absence-sidebar-card>
  } @else {
    <sb-form-field [fullWidth]="true" [noPadding]="true" [label]="'Period hours' | translate">
      <ng-content *ngTemplateOutlet="periodHoursContent" />
    </sb-form-field>
    <sb-form-field
      [fullWidth]="true"
      [noPadding]="true"
      [label]="('Contract hours' | translate) + ' ' + (expectedHours.week_contract.contract_hours | decimalToTime)"
    >
      <ng-content *ngTemplateOutlet="contractHoursContent" />
    </sb-form-field>
  }
} @else if (canApprove) {
  @if (asCard) {
    <absence-sidebar-card [title]="'Contract' | translate">
      <ng-container *ngTemplateOutlet="noContractContent" />
    </absence-sidebar-card>
  } @else {
    <sb-form-field [fullWidth]="true" [noPadding]="true" [label]="'Contract' | translate">
      <ng-content *ngTemplateOutlet="noContractContent" />
    </sb-form-field>
  }
}

<ng-template #periodHoursContent>
  {{
    'Scheduled: [scheduledHours] Contract: [contractHours]'
      | translate
      | translationParams
        : {
            scheduledHours: expectedHours.scheduled | decimalToTime,
            contractHours: expectedHours.contract | decimalToTime
          }
  }}
</ng-template>

<ng-template #contractHoursContent>
  <div class="grid grid-cols-7 gap-1">
    @for (weekDay of weekDays; track $index) {
      <span>{{ weekDay }}</span>
    }
    <span>{{ expectedHours.week_contract.mo | decimalToTime }}</span>
    <span>{{ expectedHours.week_contract.tu | decimalToTime }}</span>
    <span>{{ expectedHours.week_contract.we | decimalToTime }}</span>
    <span>{{ expectedHours.week_contract.th | decimalToTime }}</span>
    <span>{{ expectedHours.week_contract.fr | decimalToTime }}</span>
    <span>{{ expectedHours.week_contract.sa | decimalToTime }}</span>
    <span>{{ expectedHours.week_contract.su | decimalToTime }}</span>
  </div>
</ng-template>

<ng-template #noContractContent>
  <span class="text-error-500">{{ 'No contract available for the given period' | translate }}</span>
</ng-template>
