<ng-template #warningTooltip>
  <ng-content></ng-content>
</ng-template>

<div
  class="mr-1 flex h-full items-center space-x-1"
  *ngIf="absenceWarning || doubleRegistration || deviatesFromSchedule"
>
  <sb-icon
    *ngIf="absenceWarning || doubleRegistration"
    [sbTooltip]="warningTooltip"
    [size]="'lg'"
    class="text-error-500"
    name="exclamation-circle-solid"
  >
  </sb-icon>

  <!-- TODO arbitrary width/height values for now to make sure it has the same dimensions as all the icons used in other places to display schedule deviations
    I have a pr in the works to replace all these icons with `shift-warning` or `timesheet-info` components, after which they can be replaced by tailwind classes
    But since this patch is done as part of a regression fix, time is of the essence
  -->
  <div
    *ngIf="deviatesFromSchedule"
    [sbTooltip]="warningTooltip"
    class="h-[7px] w-[7px] rounded-full bg-error-500"
  ></div>
</div>
