import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipDirective } from '@sb/tooltip';
import { IconComponent } from '@sb/ui';

@Component({
  selector: 'shift-warning-tooltip',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shift-warning-tooltip.component.html',
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipDirective],
})
export class ShiftWarningTooltipComponent {
  @Input()
  public absenceWarning: boolean;
  @Input()
  public doubleRegistration: boolean;
  @Input()
  public deviatesFromSchedule: boolean;
}
