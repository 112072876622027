import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EmployeeModel } from '@reducers/orm/employee/employee.model';
import { AbsenceBalanceStats } from '@reducers/orm/time-off-balance/time-off-balance.model';
import { selectEntities as selectBalanceEntities } from '@reducers/orm/time-off-balance/time-off-balance.selectors.ts';
import { TimeOffBalanceService } from '@reducers/orm/time-off-balance/time-off-balance.service';
import { TooltipModule } from '@sb/tooltip';
import { IconComponent } from '@sb/ui';
import { endOfYear, format, subDays } from 'date-fns';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, filter, forkJoin, switchMap, withLatestFrom } from 'rxjs';

import { fieldChangeWithStart } from '../../../../forms/form.helper';
import { DecimalToDurationFormatPipe } from '../../../../pipes/decimal-to-duration-format.pipe';
import { LocaleDatePipe } from '../../../../pipes/locale-date.pipe';
import { TranslationParamsPipe } from '../../../../pipes/translation-params.pipe';
import { AppState } from '../../../../reducers';
import { parseDate } from '../../../../shared/date.helper';
import { TreeviewItem } from '../../../../shared/ngx-treeview/treeview-item';
import { SbFormFieldComponent } from '../../../../shared/sb-lib/forms/sb-form-field.component';
import { isPeriodValid } from '../absence-request.helper';
import { AbsenceSidebarCardComponent } from './absence-sidebar-card.component';

@Component({
  selector: 'absence-balance-stats',
  templateUrl: './absence-balance-stats.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TooltipModule,
    DecimalToDurationFormatPipe,
    TranslationParamsPipe,
    LocaleDatePipe,
    SbFormFieldComponent,
    IconComponent,
    AbsenceSidebarCardComponent,
  ],
})
export class AbsenceBalanceStatsComponent implements OnInit, OnChanges {
  @Input() public absenceTypeCtrl: UntypedFormControl;
  @Input() public periodCtrl: UntypedFormControl;
  @Input() public timeOffBalanceOptions: SelectItem<string>[] = [];
  @Input() public employee: EmployeeModel;

  // TODO temporary input, can be removed once we have reintroduced the sidebar in absence edit
  @Input() public asCard = false;

  public balanceStats: AbsenceBalanceStats[] = [];
  public absenceEndDate: string;
  public balanceEndDate: string;
  public endContractDate?: string;

  private readonly employee$ = new BehaviorSubject<EmployeeModel | null>(null);
  private readonly timeOffBalanceOptions$ = new BehaviorSubject<SelectItem<string>[]>([]);

  public constructor(
    private readonly store: Store<AppState>,
    private readonly timeOffBalanceService: TimeOffBalanceService,
    private readonly destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    this.balanceStatsSubscription();
    this.periodChangesSubscription();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['employee'] && changes['employee'].currentValue?.id !== changes['employee'].previousValue?.id) {
      this.employee$.next(this.employee);
    }
    if (changes['timeOffBalanceOptions']) {
      this.timeOffBalanceOptions$.next(this.timeOffBalanceOptions);
    }
  }

  public periodChangesSubscription() {
    void combineLatest([fieldChangeWithStart(this.periodCtrl), this.employee$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([period]) => {
          this.absenceEndDate = Array.isArray(period) ? period[1] : period;

          if (this.employee.enddate) {
            this.endContractDate = format(subDays(parseDate(this.employee.enddate), 1), 'yyyy-MM-dd');
          }

          this.balanceEndDate =
            this.endContractDate &&
            new Date(this.endContractDate).getFullYear() === new Date(this.absenceEndDate).getFullYear()
              ? this.endContractDate
              : format(endOfYear(parseDate(this.absenceEndDate)), 'yyyy-MM-dd');
        },
      });
  }

  /**
   * TODO improve performance (or get rid of) `getTimeOffBalancesForAbsencePolicy`
   * Context: even though the internals of this observable depend on the period, we deliberately don't listen for its changes here to prevent redundant api calls.
   * we implicitely listen for period changes because they cause the timeOffBalanceOptions to be updated, which in turn will triggers this balanceStatsSubscription. But because `getTimeOffBalancesForAbsencePolicy` is extremely slow (+500ms on my machine) we can't simply rely on a debounce to prevent redundant calls.
   */
  private balanceStatsSubscription() {
    void combineLatest([this.employee$, this.timeOffBalanceOptions$])
      .pipe(
        filter(() => isPeriodValid(this.periodCtrl) && this.absenceTypeCtrl.valid),
        switchMap(() => {
          const userId = this.employee.id;
          const period = this.periodCtrl.value;
          const endDate = Array.isArray(period) ? period[1] : period;

          return forkJoin([
            this.timeOffBalanceService.getTimeOffBalanceForUserAndDate(userId, endDate),
            this.timeOffBalanceService.getTimeOffBalanceForUserAndDate(
              userId,
              format(endOfYear(parseDate(endDate)), 'yyyy-MM-dd'),
            ),
          ]);
        }),
        withLatestFrom(this.store.select(selectBalanceEntities)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([[endOfDateBalances, endOfYearBalances], balances]) => {
        this.balanceStats = this.timeOffBalanceOptions?.map((balance: TreeviewItem) => {
          const unit = balances[balance.value]?.unit;

          return {
            id: balance.value,
            name: balance.text,
            periodEnd: <number>(endOfDateBalances[balance.value] ?? 0),
            yearEnd: <number>(endOfYearBalances[balance.value] ?? 0),
            unit,
          };
        });
      });
  }
}
